.status__content,
.reply-indicator__content {

  p {
    line-height: 26px;
  }

  blockquote {
    margin-bottom: 20px;
    padding-left: 10px;
    border-left: 3px solid $ui-base-lighter-color;
    color: $darker-text-color;
    white-space: nowrap;

    &:last-child {
      margin-bottom: 0;
    }
  }

  pre {
    padding: 4px;
  }

  strong,
  b {
    font-weight: 700;
  }

  em,
  i {
    font-style: italic;
  }

  sub {
    font-size: smaller;
    text-align: sub;
  }

  hr {
    height: .25em;
    padding: 0;
    margin: 24px 0;
    background-color: $ui-base-lighter-color;
    border: 0;
  }

  code {
    font-family: $font-monospace, monospace;
    background-color: darken($ui-base-color, 8%);
    border-radius: 3px;
    font-size: 0.85em;
    padding: 0.075em 0.4em;
  }

  del,
  s {
    text-decoration: line-through;
  }

  /*headers*/
h1,h2,h3,h4,h5,h6 {
	display: block;
	font-weight:bold;
}

h1 {
	font-size: 1.8em;
  line-height: 110%;
	margin-top: 0.67em;
	margin-bottom: 0.67em;
}

h2 {
	font-size: 1.5em;
	margin-top: 0.83em;
	margin-bottom: 0.83em;
}

h3 {
	font-size: 1.17em;
	margin-top: 1em;
	margin-bottom: 1em;
}

h4 {
	font-size: 1em;
	margin-top: 1.33em;
	margin-bottom: 1.33em;
}

h5 {
	font-size: 1em;
	margin-top: 1.33em;
	margin-bottom: 1.33em;
}

h6 {
	font-size: 1em;
	margin-top: 1.33em;
	margin-bottom: 1.33em;
}

  ul,
  ol {
    margin-left: 2em;
    margin-bottom: 20px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  ul:first-child,
  ol:first-child {
    margin-top: 0;
  }
  ul:last-child,
  ol:last-child {
    margin-bottom: 0;
  }

  .poll ul {
    margin: 0;
    list-style: none;
  }

  table {
    overflow-x: auto;
    display: block;
  }

  img {
    max-width: 100%;
  }
}